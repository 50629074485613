import { Fullfillment } from '@hooks';
import { ScreenName } from '@packages/event-tracking';

import { BaseEventPayload } from './../../../packages/event-tracking/useGetBaseEventPayload';

export enum CartActionType {
  'AddToCart' = 'AddToCart',
  'EditCartItem' = 'EditCartItem',
  'DeleteCartItem' = 'DeleteCartItem',
  'CartCheckout' = 'CartCheckout',
  'CartView' = 'CartViewBeforeCheckout',
}

export const CartEventName = 'CartOrder';

export type CartOrderEventPayload = BaseEventPayload & {
  userBehavior?: {
    isFrom: ScreenName | null;
  };
} & {
  action: CartActionType.AddToCart | CartActionType.DeleteCartItem | CartActionType.EditCartItem;
  eventDetail: {
    businessName: string;
    distance?: number;
    storefrontId: string;
    catalogItemName: string;
    catalogId: string;
    quantity?: number;
    timeframe?: string;
    fulfillmentMethod?: Fullfillment;
    additionalRequestProvided: boolean;
    price: number;
  };
};

export type CartCheckoutEventPayload = BaseEventPayload & {
  action: CartActionType.CartCheckout;
  eventDetail: {
    cartTotal: number;
    numberOfStorefronts: number;
    countOfItems: number;
    emailMarketingOptIn?: boolean;
  };
};

export type CartViewEventPayload = BaseEventPayload & {
  action: CartActionType.CartView;
  eventDetail: {
    cartTotal: number;
    numberOfStorefronts: number;
    countOfItems: number;
  };
};
