import { Form } from 'react-bootstrap';
import { Control, Controller, FieldPath, RegisterOptions } from 'react-hook-form';

type SelectFieldProps<T extends object, U extends FieldPath<T>> = {
  control: Control<T, any>;
  rules?: Omit<RegisterOptions<T, U>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  name: U;
  label: string;
  options: Array<{
    label: string;
    value: string;
  }>;
};
export const SelectField = <T extends object, U extends FieldPath<T>>({
  label,
  control,
  name,
  options,
  rules,
}: SelectFieldProps<T, U>) => {
  return (
    <Controller<T, U>
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <>
          <Form.Select
            {...field}
            size="lg"
            className="w-100"
            style={{ minWidth: 150, width: 'unset', paddingRight: '1rem' }}
            isInvalid={!!fieldState.error}
            placeholder={label}
          >
            <option value="">{label}</option>
            {options.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          {fieldState.error && (
            <>
              <Form.Text className="text-danger fs-xs">{fieldState.error?.message}</Form.Text>
              <Form.Control.Feedback type="invalid">
                {fieldState.error?.message}
              </Form.Control.Feedback>
            </>
          )}
        </>
      )}
    />
  );
};
