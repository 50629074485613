import { useEffect } from 'react';

import { usePreviousScreen } from '@hooks';
import {
  EventTriggerElement,
  trackMixpanelEvent,
  useGetBaseEventPayload,
} from '@packages/event-tracking';
import { fbq } from '@packages/fbpixel';
import { ga4 } from '@packages/ga4';

import { CartActionType, CartEventName, CartOrderEventPayload } from './types';

export const cartPubSub = {
  publish: (
    action: CartActionType,
    payload: {
      eventDetail: CartOrderEventPayload['eventDetail'];
    }
  ) => {
    PubSub.publish(action, payload);
  },
  subscribe: (
    action: CartActionType,
    fn: (payload: { eventDetail: CartOrderEventPayload['eventDetail'] }) => void
  ) => PubSub.subscribe(action, (_message, payload) => fn(payload)),
};

export const useTrackCartEvent = (
  action: CartActionType.AddToCart | CartActionType.DeleteCartItem | CartActionType.EditCartItem,
  component: string,
  element: EventTriggerElement,
  label: string,
  distanceToStore?: number
) => {
  const getBaseEventPayload = useGetBaseEventPayload();
  const { previousScreen } = usePreviousScreen();

  useEffect(() => {
    const checkoutEventUnsubscribeToken = cartPubSub.subscribe(action, async ({ eventDetail }) => {
      const baseEventPayload = await getBaseEventPayload({
        component,
        element,
        label,
      });
      const payload: CartOrderEventPayload = {
        action,
        eventDetail: {
          ...eventDetail,
          distance: distanceToStore,
        },
        userBehavior: {
          isFrom: previousScreen ?? null,
        },
        ...baseEventPayload,
      };
      trackMixpanelEvent(CartEventName, payload);
      fbq.addToCartEvent({
        currency: 'USD',
        num_items: eventDetail.quantity ?? 1,
        value: eventDetail.price,
        customProperties: payload,
      });
      ga4.addToCartEvent({
        currency: 'USD',
        num_items: eventDetail.quantity ?? 1,
        value: eventDetail.price,
        customProperties: payload,
      });
    });

    return () => {
      PubSub.unsubscribe(checkoutEventUnsubscribeToken);
    };
  }, [action, component, element, getBaseEventPayload, label, previousScreen, distanceToStore]);
};
