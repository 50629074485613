import { Control, FieldPath } from 'react-hook-form';

import { getDeliveryOptionLabelByValue } from '@components/ContactForm/DeliveryMethodsField';
import { SelectField } from '@components/SelectField';

import { OrderItemStoreVM } from './useCartOrder';

type DeliveryMethodSelectFieldProps<T extends object> = {
  control: Control<T, any>;
  store: OrderItemStoreVM | undefined;
  name: FieldPath<T>;
};
export const DeliveryMethodSelectField = <T extends object>({
  control,
  store,
  name,
}: DeliveryMethodSelectFieldProps<T>) => {
  const deliveryOptionLabelByValue = getDeliveryOptionLabelByValue(store?.owner.firstName ?? '');
  const deliveryOptions = (store?.fulfillmentOptions ?? []).map((item) => ({
    label: deliveryOptionLabelByValue[item],
    value: item,
  }));
  return (
    <SelectField name={name} control={control} label="Delivery Method" options={deliveryOptions} />
  );
};
