import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

export const DescriptionField = ({ label }: { label: string }) => {
  const { register, formState } = useFormContext<{ note: string }>();

  return (
    <div>
      <Form.FloatingLabel label={label}>
        <Form.Control
          as={TextareaAutosize}
          minRows={4}
          {...register('note')}
          isInvalid={!!formState.errors.note}
          className="rounded rounded-3 shadow-sm"
          style={{ resize: 'none' }}
          placeholder={label}
        />
        <Form.Control.Feedback type="invalid">
          {formState.errors.note?.message}
        </Form.Control.Feedback>
      </Form.FloatingLabel>
    </div>
  );
};
