import { Form, FormCheck } from 'react-bootstrap';
import { UseFormRegister } from 'react-hook-form';

import { Fullfillment } from '@hooks';

import { AddItemToCartFormVM } from './constants';

export const getDeliveryOptionLabelByValue = (ownerName: string) => ({
  'Local Delivery': `Local delivery by ${ownerName}`,
  'Pick Up': `Pick up from ${ownerName}`,
  Shipping: 'Shipping',
  'Virtual Service': 'Virtual Service',
  Onsite: 'Onsite Service',
});

export const DeliveryMethodsField = ({
  storeOwnerName,
  registerField,
  currentValue,
  deliveryOptions,
}: {
  currentValue?: string;
  deliveryOptions: Fullfillment[];
  storeOwnerName: string;
  registerField: UseFormRegister<AddItemToCartFormVM>;
}) => {
  return (
    <Form.Group>
      {deliveryOptions.map((value) => (
        <FormCheck
          type="radio"
          key={value}
          id={value}
          checked={value === currentValue}
          {...registerField('delivery.method', { deps: ['delivery'] })}
          value={value}
          label={getDeliveryOptionLabelByValue(storeOwnerName)[value]}
        />
      ))}
    </Form.Group>
  );
};
