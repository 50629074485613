import React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { formatDate } from '@utils';

type DatePickerFieldProps<T extends object> = {
  control: Control<T, any>;
  name: FieldPath<T>;
  onBlur?: () => void;
};

export const DatePickerFieldInner = <T extends object>(
  { control, name, onBlur: onBlurFromProps }: DatePickerFieldProps<T>,
  ref: React.ForwardedRef<ReactDatePicker<string, boolean | undefined> | null>
) => {
  return (
    <Controller<T>
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value }, fieldState }) => (
        <div>
          <Form.Control
            placeholderText="Enter date"
            as={DatePicker}
            ref={ref}
            size="lg"
            selected={value ? new Date(value) : null}
            // onChange typings here is wrong, should cast to any to remove ts error
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={((e: Date | null) => onChange(e && formatDate(e))) as any}
            onBlur={() => {
              onBlur();
              onBlurFromProps?.();
            }}
            style={{ minWidth: 150, width: 'unset', paddingRight: '1rem' }}
            isInvalid={!!fieldState.error}
          />
          {fieldState.error && (
            <Form.Text className="text-danger fs-xs">{fieldState.error?.message}</Form.Text>
          )}
        </div>
      )}
    />
  );
};

export const DatePickerField = React.forwardRef(DatePickerFieldInner);
