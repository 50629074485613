import Link from 'next/link';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useUnmount } from 'react-use';

import { ClientOnly } from '@components/ClientOnly';
import { AuthNav } from '@components/Header/AuthNav';
import { CategoriesAllMenu } from '@components/Header/CategoriesAllMenu';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';
import { useMenuVisibilityState } from '@hooks/useMenuVisibilityState';

export const FullScreenMenu = () => {
  const { isMenuVisible, setIsMenuVisible } = useMenuVisibilityState();
  const isTablet = useIsMediaBreakpoint('md');
  const isDesktop = useIsMediaBreakpoint('lg');

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
  }, [isMenuVisible]);

  useUnmount(() => {
    setIsMenuVisible(false);
  });

  return (
    <>
      {isMenuVisible && (
        <div
          className="position-fixed top-0 left-0 bg-primary w-100 vh-100 overflow-auto"
          style={{
            zIndex: 1080,
            color: '#DDFF7B',
            marginLeft: isDesktop ? '0' : isTablet ? '86px' : '-10px',
          }}
        >
          <i
            className="fi-x cursor-pointer display-6 m-3 mb-0"
            onClick={() => setIsMenuVisible(false)}
          />
          <Container fluid className="my-lg-5 pb-4">
            <Row>
              <Col xs={12} lg={6} style={{ paddingLeft: '85px' }}>
                <ClientOnly>
                  <div className="d-lg-none display-6 mb-4">
                    <AuthNav />
                  </div>
                </ClientOnly>

                <div className="text-uppercase">Shop Whats Hot</div>
                <div className="d-none d-lg-block">
                  <div>
                    <Link
                      href="/browse/category/fresh"
                      className="display-1 font-title"
                      style={{ color: '#DDFF7B', textDecoration: 'none' }}
                    >
                      What’s New
                    </Link>
                  </div>
                  <div>
                    <Link
                      href="/browse/category/trending"
                      className="display-1 font-title"
                      style={{ color: '#DDFF7B', textDecoration: 'none' }}
                    >
                      Trending
                    </Link>
                  </div>
                  <div>
                    <Link
                      href="/"
                      className="display-1 font-title"
                      style={{ color: '#DDFF7B', textDecoration: 'none' }}
                    >
                      Seasonal
                    </Link>
                  </div>
                </div>

                <div className="d-lg-none">
                  <CategoriesAllMenu />
                </div>

                <div className="mt-5 text-uppercase">Sell</div>
                <div>
                  <Link
                    target="_blank"
                    href="https://www.westtenth.com/sellwithus"
                    className="display-1 font-title"
                    style={{ color: '#DDFF7B', textDecoration: 'none' }}
                  >
                    Sell With Us
                  </Link>
                </div>

                <div className="mt-5 text-uppercase">Company</div>
                <div>
                  <Link
                    target="_blank"
                    href="https://www.westtenth.com/about"
                    className="display-1 font-title"
                    style={{ color: '#DDFF7B', textDecoration: 'none' }}
                  >
                    About
                  </Link>
                </div>
              </Col>
              <Col
                lg={6}
                className="d-none d-lg-flex align-items-end font-serif"
                style={{ fontSize: '1.5rem' }}
              >
                Sign up now to start selling on West Tenth. Meet new customers and build community.
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
