import { z } from 'zod';

import { isInThePast, isValidDate } from '@components/ContactForm/constants';
import { shouldRenderLocation } from '@components/ContactForm/shouldRenderLocation';
import { validationMessages } from '@constants';
import { DeliveryMethodSchema } from '@hooks/useStores';

import { getTimeframeValidationSchema } from './../../components/ContactForm/constants';

export const updateCartItemFormSchema = z.object({
  note: z.string().trim().optional(),
  timeframe: z
    .string()
    .trim()
    .nullable()
    .optional()
    .refine(
      (timeframe) => {
        if (timeframe) {
          const date = new Date(timeframe);
          return date && isValidDate(date) && !isInThePast(date);
        }
        return true;
      },
      {
        message: "Selected timeframe can't be in the past",
      }
    ),
  delivery: z
    .object({
      method: DeliveryMethodSchema.optional(),
      location: z.string().trim().optional(),
    })
    .optional()
    .refine((delivery) => Boolean(delivery?.method), {
      message: validationMessages.deliveryMethodRequired,
    })
    .refine(
      (delivery) => {
        if (delivery?.method && shouldRenderLocation(delivery.method) && !delivery?.location) {
          return false;
        }
        return true;
      },
      {
        message: validationMessages.locationRequired,
      }
    ),
  quantity: z.number().min(1).max(10).int(),
});

export const deliveryValidationSchema = z
  .object({
    method: DeliveryMethodSchema.optional(),
    location: z.string().trim().optional(),
  })
  .optional()
  .refine((delivery) => Boolean(delivery?.method), {
    message: validationMessages.deliveryMethodRequired,
  })
  .refine(
    (delivery) => {
      if (delivery?.method && shouldRenderLocation(delivery.method) && !delivery?.location) {
        return false;
      }
      return true;
    },
    {
      message: validationMessages.locationRequired,
    }
  );

export const addCartItemFormSchema = z.object({
  note: z.string().trim().optional(),
  timeframe: getTimeframeValidationSchema({ isTimeframeRequired: true }),
  delivery: deliveryValidationSchema,
  quantity: z.number().min(1).max(10).int(),
});

export type UpdateCartItemFormVM = z.infer<typeof updateCartItemFormSchema>;
