import { Button, Form, InputGroup } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

export const QuantityField = () => {
  const { register, formState, setValue, watch } = useFormContext<{ quantity?: number }>();
  const quantity = watch('quantity') ?? 1;

  const canDecrease = quantity > 1;
  const canIncrease = quantity < 10;

  const handleDecrease = () => (canDecrease ? setValue('quantity', quantity - 1) : null);
  const handleIncrease = () => (canIncrease ? setValue('quantity', quantity + 1) : null);

  return (
    <div>
      <InputGroup>
        <Button
          onClick={handleDecrease}
          variant="outline-secondary"
          className={`${canDecrease ? '' : ' cursor-default opacity-50'}`}
        >
          <i className="fi-minus" />
        </Button>
        <Form.FloatingLabel label="Quantity">
          <Form.Control
            type="number"
            {...register('quantity', { valueAsNumber: true })}
            defaultValue={1}
            isInvalid={!!formState.errors.quantity}
            placeholder="Quantity"
          />
        </Form.FloatingLabel>
        <Button onClick={handleIncrease} variant="outline-secondary">
          <i className="fi-plus" />
        </Button>
      </InputGroup>
      <Form.Control.Feedback type="invalid">
        {formState.errors.quantity?.message}
      </Form.Control.Feedback>
    </div>
  );
};
